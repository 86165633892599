import loadingUri from '../../assets/loading.svg';
import { LoadingContext } from '../../contexts/loading-context';
import './full-page-loading.scss';

import { useContext } from 'react';

const FullPageLoading = () => {
  const { isLoading } = useContext(LoadingContext)!;

  return isLoading ? (
    <div id="full-page-loading">
      <img className="loading" src={loadingUri} alt="loading animation" />
      <p>Hang tight, this should be quick.</p>
    </div>
  ) : (
    <></>
  );
};

export default FullPageLoading;
