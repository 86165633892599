import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (
  name: string,
  {
    defaultValue = null,
    save = false,
  }: { defaultValue?: string | null; save?: boolean } = {},
): [string | null, (v: string | null, options?: { replace: boolean }) => void] => {
  const setParams = useSearchParams()[1];
  const params = new URLSearchParams(window.location.search); // useSearchParams()[0] does not update quickly enough

  const setParam = useCallback(
    (newValue: string | null, { replace }: { replace: boolean } = { replace: false }) => {
      if (newValue === '') {
        newValue = null;
      }

      const parsedParams = Object.fromEntries(
        new URLSearchParams(window.location.search).entries(), // useSearchParams()[0] does not update quickly enough
      );

      if (newValue === null || newValue === undefined) {
        if (parsedParams[name] !== null && parsedParams[name] !== undefined) {
          delete parsedParams[name];
          setParams(parsedParams, { replace });
        }
        if (save) {
          localStorage.removeItem(name);
          setParams(parsedParams, { replace: true }); // trigger a refresh
        }
      } else if (parsedParams[name] !== newValue) {
        parsedParams[name] = newValue;
        if (save) {
          localStorage.setItem(name, newValue);
        }
        setParams(parsedParams, { replace });
      }
    },
    [setParams, name, save],
  );

  const value =
    params.get(name) ?? (save ? localStorage.getItem(name) : null) ?? defaultValue;

  useEffect(() => {
    if (!params.get(name) && value) {
      setParam(value, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [params.get(name), setParam];
};
