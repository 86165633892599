import { useQueryParam } from './use-query-param';

export const useIntegerQueryParam = (
  name: string,
  {
    defaultValue = null,
    save = false,
  }: { defaultValue?: number | null; save?: boolean } = {},
): [
  value: number | null,
  setValue: (v: number | null, options?: { replace: boolean }) => void,
] => {
  const [rawValue, setRawValue] = useQueryParam(name, {
    defaultValue: defaultValue?.toString(),
    save,
  });

  let intValue: number | null = Number.parseInt(rawValue!);

  if (Number.isNaN(intValue)) {
    intValue = defaultValue;
  }

  return [
    intValue,
    (v: number | null, options?: { replace: boolean }) =>
      setRawValue(v?.toString() ?? null, options),
  ];
};
