import { runningEnvironment } from './helper/environment';

export const commonConfig = {};

const environmentConfigs = {
  development: {
    baseUri: 'http://localhost:5010/',
    showWatermark: true,
  },
  staging: {
    baseUri: 'https://patent-service.internal-staging.scoutinscience.com/',
    showWatermark: true,
  },
  production: {
    baseUri: 'https://patent-service.internal.scoutinscience.com/',
    showWatermark: false,
  },
};

export const config = {
  ...commonConfig,
  ...environmentConfigs[runningEnvironment],
};
