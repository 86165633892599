import exitUri from '../../assets/exit.svg';
import loadingUri from '../../assets/loading.svg';
import { config } from '../../config';
import { LoadingContext } from '../../contexts/loading-context';
import { ModalContext } from '../../contexts/modal-context';
import { AnalysisResponse } from '../../models/analysis-response';
import { useIntegerQueryParam } from '../../services/use-integer-query-param';
import './analyser.scss';

import Slider from '@mui/material/Slider';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Analyser = () => {
  const { update: updateModal } = useContext(ModalContext)!;
  const [files, setFiles] = useState<Array<File>>([]);
  const [countCache, setCountCache] = useState<{ [q: string]: number }>({});
  const [results, setResults] = useState<AnalysisResponse | null>(null);
  const { executeWithLoadingScreen } = useContext(LoadingContext)!;
  const ref = useRef<HTMLElement>(null);

  const [termCount, setTermCount] = useIntegerQueryParam('tc', {
    save: true,
    defaultValue: 10,
  });

  const [synonymCount, setSynonymCount] = useIntegerQueryParam('sc', {
    save: true,
    defaultValue: 10,
  });

  const synonyms = results?.terms.slice(0, termCount!).flatMap((t) => t.similar);
  synonyms?.sort((a, z) => z.score - a.score);

  const synonymsMinScore =
    synonyms && synonyms.length > 0
      ? synonyms[Math.min(synonyms.length - 1, synonymCount!)].score
      : 0;

  const activeTerms = results?.terms
    .slice(0, termCount!)
    .filter((t) => !t.hidden)
    .map((t) => ({
      ...t,
      similar: t.similar.filter((s) => !s.hidden && s.score > synonymsMinScore),
    }));

  const cacheKey = JSON.stringify(activeTerms);
  useEffect(() => {
    if (!activeTerms) {
      return;
    }

    if (activeTerms && !countCache.hasOwnProperty(cacheKey)) {
      (async () => {
        const rawResponse = await fetch(config.baseUri + 'get_match_count', {
          method: 'POST',
          body: cacheKey,
          headers: { 'Content-Type': 'application/json' },
        });

        if (rawResponse.status >= 400) {
          updateModal({
            title: 'Server error',
            text: await rawResponse.text(),
          });
          return;
        }

        const response = await rawResponse.json();
        setCountCache((c) => ({ ...c, [cacheKey]: response }));
      })();
    }
  }, [activeTerms, cacheKey, countCache, updateModal, setCountCache]);

  const sendFiles = useCallback(() => {
    const data = new FormData();

    files.forEach((f) => data.append('files', f, f.name));

    executeWithLoadingScreen(async () => {
      try {
        const rawResponse = await fetch(config.baseUri + 'find_terms', {
          method: 'POST',
          body: data,
        });

        if (rawResponse.status >= 400) {
          throw new Error(`Bad status: ${await rawResponse.text()}`);
        }

        const response = await rawResponse.json();

        setResults(response);
        ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } catch (e: any) {
        updateModal({
          title: 'Server error',
          text: e.toString(),
        });
      }
    });
  }, [files, updateModal, executeWithLoadingScreen]);

  const onDrop = useCallback(
    (accepted: Array<File>) =>
      accepted.forEach((file: File) => setFiles((v) => [...v, file])),
    [setFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'text/html': ['.html', '.htm'],
      'text/plain': ['.txt', '.md'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
        '.docx',
      ],
    },
  });

  const googlePatentURI =
    'https://patents.google.com/?language=ENGLISH&' +
    activeTerms
      ?.map(
        (t) =>
          'q=' +
          [
            (t.is_negative ? '-' : '') + t.tokens + (t.ends_in_wildcard ? '*' : ''),
            ...t.similar.map((s) => s.tokens),
          ]
            .join(',')
            .replace('_', '+'),
      )
      .join('&');

  let espacenetURI =
    'https://worldwide.espacenet.com/patent/search?queryLang=en&q=' +
    activeTerms
      ?.filter((t) => !t.is_negative)
      .map((t) =>
        [t.tokens + (t.ends_in_wildcard ? '*' : ''), ...t.similar.map((s) => s.tokens)]
          .map((w) => `nftxt any "${w}"`)
          .join(' OR '),
      )
      .map((c) => (c.includes(' OR ') ? `(${c})` : c))
      .join(' AND ');

  const negatives = activeTerms
    ?.filter((t) => t.is_negative)
    .map((w) => `nftxt any "${w.tokens}"`)
    .join(' OR ');

  if (negatives && negatives.length > 0) {
    espacenetURI += ` NOT (${negatives})`;
  }

  return (
    <article id="analyser">
      <section className="upload-area">
        <h2>Upload your files</h2>

        <div id="drop-area" {...getRootProps()} className={isDragActive ? 'active' : ''}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag and drop your files here.</p>
          )}
          <div id="files">
            {files.map((f, i) => (
              <div className="file" key={`${f.name}-${i}`}>
                <img
                  className="exit"
                  alt="exit"
                  src={exitUri}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiles((v) => v.filter((f2) => f2 !== f));
                    setResults(null);
                  }}
                />
                <p>{f.name}</p>
              </div>
            ))}
          </div>
        </div>

        <button
          className="large"
          onClick={(e) => {
            sendFiles();
            e.preventDefault();
          }}
          disabled={files.length === 0}
        >
          Submit
        </button>
      </section>

      <section className="customise" ref={ref}>
        {results &&
          (results.terms.length > 0 ? (
            <>
              <h2>Customize your query</h2>

              <section className="count">
                <p>
                  Number of matches (estimate):{' '}
                  {countCache[cacheKey] ?? <img src={loadingUri} alt="loading" />}
                </p>
              </section>

              <header className="sliders">
                <div>
                  <h4>Query length</h4>
                  <Slider
                    marks={[
                      { value: 0, label: 0 },
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 25, label: 25 },
                      { value: 50, label: 50 },
                    ]}
                    min={1}
                    max={results.terms.length}
                    getAriaLabel={() => 'Length'}
                    value={termCount!}
                    onChange={(_, value) => {
                      setTermCount(value as number, { replace: true });
                    }}
                    valueLabelDisplay="auto"
                  />
                </div>

                <div>
                  <h4>Query width</h4>
                  <Slider
                    marks={[
                      { value: 0, label: 0 },
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 25, label: 25 },
                      { value: 50, label: 50 },
                    ]}
                    min={1}
                    max={synonyms!.length}
                    getAriaLabel={() => 'Length'}
                    value={synonymCount!}
                    onChange={(_, value) => {
                      setSynonymCount(value as number, { replace: true });
                    }}
                    valueLabelDisplay="auto"
                  />
                </div>
              </header>

              <div className="terms">
                {results.terms.slice(0, termCount!).map((t) => (
                  <div
                    key={t.tokens}
                    className={
                      'term ' +
                      (t.hidden ? 'hidden ' : '') +
                      (t.is_negative ? 'negative' : '')
                    }
                    onClick={() => {
                      setResults((r) => {
                        if (!r) {
                          return null;
                        }

                        const newR: AnalysisResponse = structuredClone(r);
                        const newT = newR.terms.find((t2) => t2.tokens === t.tokens)!;
                        newT.hidden = !newT.hidden;

                        return newR;
                      });
                    }}
                  >
                    <h3>
                      {t.tokens.replace('_', ' ')}
                      {t.ends_in_wildcard && '*'}
                    </h3>

                    <div className="synonyms">
                      {t.similar
                        .filter((s) => s.score > synonymsMinScore)
                        .map((s) => (
                          <span
                            key={s.tokens}
                            className={
                              s.hidden ? 'hidden' : '' + (t.is_negative ? 'negative' : '')
                            }
                            onClick={(e) => {
                              e.stopPropagation();

                              setResults((r) => {
                                if (!r) {
                                  return null;
                                }

                                const newR: AnalysisResponse = structuredClone(r);
                                const newT = newR.terms.find(
                                  (t2) => t2.tokens === t.tokens,
                                )!;
                                const newS = newT.similar.find(
                                  (s2) => s2.tokens === s.tokens,
                                )!;
                                newS.hidden = !newS.hidden;

                                return newR;
                              });
                            }}
                          >
                            {s.tokens.replace('_', ' ')}
                          </span>
                        ))}
                    </div>
                  </div>
                ))}
              </div>

              <h2>Export your query</h2>
              <div className="export">
                <div>
                  <h3>Google Patent</h3>
                  <a href={googlePatentURI} target="_blank" rel="noreferrer">
                    {googlePatentURI}
                  </a>
                </div>

                <div>
                  <h3>Espacenet</h3>
                  <a href={espacenetURI} target="_blank" rel="noreferrer">
                    {espacenetURI}
                  </a>
                </div>
              </div>
            </>
          ) : (
            <h2>No text could be extracted.</h2>
          ))}
      </section>
    </article>
  );
};

export default Analyser;
