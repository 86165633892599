import logoUri from '../assets/logo.svg';
import { LoadingContext } from '../contexts/loading-context';
import { ModalContext, ModalData } from '../contexts/modal-context';
import Analyser from './analyser/analyser';
import './app.scss';
import FullPageLoading from './full-page-loading/full-page-loading';
import Modal from './modal/modal';

import { useCallback, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const [modalValue, setModalValue] = useState<ModalData>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const executeWithLoadingScreen = useCallback(
    async (f: () => Promise<unknown>) => {
      setIsLoading(true);
      const result = await f();
      setIsLoading(false);
      return result;
    },
    [setIsLoading],
  );

  return (
    <BrowserRouter>
      <ModalContext.Provider value={{ data: modalValue, update: setModalValue }}>
        <LoadingContext.Provider
          value={{ executeWithLoadingScreen, setIsLoading, isLoading }}
        >
          <Modal />
          <FullPageLoading />

          <header>
            <a href="https://scoutinscience.com/">
              <img src={logoUri} alt="logo" />
            </a>
            <h1>Innovation to Patent Query</h1>
          </header>

          <section className="info">
            <p>
              Find the perfect query for conducting prior art discovery. Upload as many
              relevant documents to the innovation as you wish. These can be PDFs, MS Word
              documents, webpages or plain text files. Our algorithm will cross-reference
              every term of your documents with our database of millions of patents and
              give you back the most unique and descriptive words and their potential
              synonyms.
            </p>
            <p>
              Terms are also gathered that are common in similar documents but not in any
              of the uploaded ones. These are highlighted with a red border.
            </p>
            <p>
              Customise your query with the sliders or by manually clicking on the words
              and synonyms you wish to exclude. After you're finished, just click on the
              generate Google Patents or EPO links.
            </p>
          </section>

          <main>
            <Analyser />
          </main>
        </LoadingContext.Provider>
      </ModalContext.Provider>
    </BrowserRouter>
  );
};

export default App;
